<template>
    <div class="free mb-5 content">
        <!-- content -->
        <div class="h4 text-white font-weight-bold text-center my-3">
            <div class="welfares-header family">{{ $route.meta.title }}</div>
             <div class="welfares-main family">
          <button @click="wel">援之以手</button>
          <button style="border:1px solid #9FDA35; color:#9FDA35" >福利宝箱</button>
          <button @click="curs">会员福利</button>
          <button @click="wel" >qq福利群</button>
        </div>
            <div class="roll-title" 
                @click="playingMethod = true">活动规则</div>
        </div>

        <div class="row justify-content-around" style="margin:0 0 30px 0"> 
            <router-link
                    :to="'/active/outOfTheFree/' + item.box_id+'?id='+item.id + '&type='+item.type"
                    v-for="(item,index) in box_list" :key="index"
                    class="text-center free-content bg-size-100 bg-repeat col-lg-2 col-md-3 col-sm-4 col-6 bgimg">
                <div class="">
                    <div class="bxtitle">{{item.name}}</div>
                    <div><img width="100%" :src="item.box.intact_cover" alt=""></div>
                    <div class="bxmesg">{{item.description}}<br/></div>
                </div>
            </router-link>

            <vacancy v-if="box_list.length == 0"></vacancy>
        </div>
        <!-- 玩法介绍 -->
        <div class="" v-if="playingMethod">
            <div style="width: 91%; top:15vh"
                 class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class=" p-md-4 p-2" style="line-height: 2.0;color:rgb(245, 59, 59)">

                    <p>1.达到宝箱条件即可开箱，充值达到一定金额免费开启宝箱。</p>

                    <p>2.咨询客服，新人注册，其他福利可获取cdk即可开启箱子。</p>

                </div>
                <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>

            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>

    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import $api from '@/api/outOfTheFree/index'
    export default {
        name: "indexV2",
        data() {
            return {
                blocks:[
                    { padding: '12px 19px', background: '#312414' },
                ],
                prizes:[],
                buttons: [{}],
                buttonsRepetition:true,
                activity_rules:false,
                box_list:[],
                cdk:'',
                box_gun_active:{},
                box_gun_active_is_show:false,
                playingMethod:false,
            }
        },
        computed:{
            ...mapState(['user_info'])
        },
        mounted() {
            this.init()
        },
        methods:{
            //导航按钮
            wel(){
                 this.$router.push({ name: "center" });
            }, 
            curs() {
            this.$router.push({ name: "memberVip" });
        },
            async init(){
                await this.getWelfare()

            },
            // 获取宝箱数据
            async getWelfare(){
                try {
                    let {data} = await $api.getWelfare()
                    data.forEach((item,index) =>{
                        if(item.box == null || item.box == undefined || !item.box){
                            data.splice(index,1)
                        }
                    })


                    this.box_list = data

                }catch (e) {
                    console.log(e)
                }
            },
            welfareDataPrizes(resData){
                let data = JSON.parse(JSON.stringify(resData))
                let obj =data.box_list.map((item,index) =>{
                    let obj =  {
                        skin_id:item.skins.id,
                        borderRadius:1,
                        x: index>=4? index-4>=4?index-8:index-4 : index,
                        y: index<4? 0 : index<8 ? 1:2,
                        fonts: [
                            { text: '金币 ' + item.skins.bean, fontSize: '12px', top: 5,fontColor:'#FFFFFF' },
                        ],
                        imgs:[{
                            src: item.skins.cover,
                            width:80,
                            height:60,
                            top:25,
                        }],
                        background:'rgba(246,175,24,.5)',
                    }
                    return obj
                })
                console.log(obj)
                return obj
            },

            async startCallBack(item,index){

                if(this.buttonsRepetition){
                    this.buttonsRepetition = false
                }
                let {data} = await $api.get_welfare_open({id:item.id,cdk:item.cdk})
                let i = this.box_list[index].box_list.findIndex(t =>{
                    return t.skin_id == data.skin_id
                })

                let luck_grid = this.$refs[`LuckyGridClick${index}`]
                luck_grid[0].play()
                // let sum = Math.random() * 12
                setTimeout(() => {
                    luck_grid[0].stop(i)
                }, 2000)
            },
            endCallBack (prize) {
                this.buttonsRepetition = true
                this.box_gun_active_is_show = true
                this.box_gun_active = prize
                this.$notify({
                    title: '恭喜你获得',
                    message: `${prize.fonts[0].text}，已放入您的仓库`,
                    type: 'success',
                    position: 'top-left'
                });

                console.log(prize)

            }
        }
    }
</script>

<style lang="scss" scoped>
.welfares-header {
  width: 310px;
  height: 71px;
  margin: 25px auto;
  background: url('../../assets/image/12.png');
  background-size: 100% 100%;
  // background: linear-gradient(146deg, #009FFF 0%, #009FFF 0%, #05377B 100%);
  // box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5), 2px 5px 8px 0px #000000;
  opacity: 1;
  border-radius: 20px;
  text-align: center;
  line-height: 71px;
  font-size: 30px;
  font-weight: 400;
  color: #FFFFFF;
  -webkit-text-stroke: 1px black;
}
.welfares-main {
  width: 280px;
  height: 48px;
  margin: auto;
  display: flex;
  justify-content: space-between;
margin-bottom:20px;

  button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid RGBA(0, 159, 255, 1);
    font-size: 16px;
    color: RGBA(0, 159, 255, 1);
    font-weight: 400;
  }
}
.roll-title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #98d462;
  text-align: center;
  font-style: italic;
}
.content{
    width: 90%;
    margin: 0 auto;
    &-box{
        height: 100vh;
    }
}
.bgimg{
    background: url('../../assets/image/7.png');
    background-size: 100% 100%;
    margin-bottom: 30px;
}
.bxtitle{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
}
.bxmesg{
    margin-bottom: 28px;
    font-size: 12px;
}
</style>
